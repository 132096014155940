












import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { ProductPricingSize } from '~/constants/productCardPricing';
import { PRODUCT_PRICING_SIZE } from '~/constants/productCardPricing';

export default defineComponent({
  name: 'StrikethroughPrice',
  props: {
    price: {
      type: String,
      required: true
    },
    isHiddenOnMobile: {
      type: Boolean,
      required: false
    },
    size: {
      type: String as PropType<ProductPricingSize>,
      default: PRODUCT_PRICING_SIZE.MEDIUM,
      validator: (value: ProductPricingSize) => {
        return Object.values(PRODUCT_PRICING_SIZE).includes(value);
      }
    }
  },
  setup(props) {
    const secondaryPriceSizes = {
      [PRODUCT_PRICING_SIZE.SMALL]: 'text-sm',
      [PRODUCT_PRICING_SIZE.MEDIUM]: 'text-base'
    };
    const secondaryPriceTextSize = computed(() => secondaryPriceSizes[props.size]);

    return {
      secondaryPriceTextSize
    };
  }
});
