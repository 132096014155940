export const productTag = {
  New: 'new',
  Popular: 'bestseller',
  Sale: 'on-sale',
  Bware: 'bware',
  TopRated: 'topRated',
  BlackFriday: 'campaign:black_friday',
  CyberWeek: 'campaign:cyber_week'
} as const;
export type ProductTag = typeof productTag[keyof typeof productTag] | string;
